import React, { useState, useEffect, useMemo, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components";
import * as Ant from "antd";
import { navigate } from "gatsby";
import { withReact, Slate } from "slate-react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import { Helmet } from "react-helmet";
import moment from "moment";
import _ from "lodash";
import hexToRgba from "hex-to-rgba";

import "../index.scss";
import * as SvgIcon from "./SvgIcon";
import * as Survey from "../Contexts/SurveyContext";
import Widget, { Color, Font, FontSize } from "./Widget";
import { buildPager, buildOneQuestionPerPagePager } from "./SurveyPage";
import QuestionDisplay from "./LiveQuestion";
import LogicMatcher from "../LogicMatcher";
import ValidationCodeInput from "./ValidationCodeInput";
import RichTextEditor from "./RichTextEditor";
import FinishPage from "../Templates/FinishPage";
import WelcomePage from "../Templates/WelcomePage";
import chtLogo from "../images/CHTLogo_color.svg";
import ErrorPage from "../Templates/ErrorPage";
import { themeColor } from "../Utils/themeColor";

// 2022-08-03 ALVIN 調整程式宣告位置
const queryString = require("query-string");
const RichTextUtils = require("../Utils/RichTextUtils");

const REAL_QUESTIONS = [
  "SINGLE_CHOICE",
  "MULTIPLE_CHOICE",
  "FILL_IN",
  "MATRIX",
  "BASIC_INFO",
  "NUMERICAL",
];

/**
 * 排序功能
 * @params {arr} array 排序物件
 * @return {ErrorPage}
 */
const shuffle = arr => {
  let result = [];
  let _arr = [...arr];
  while (result.length < arr.length) {
    let pickedIdx = 0;
    // let random = Math.floor(Math.random() * _arr.length);
    let cryptoRandom = new Uint32Array(1);
    window.crypto.getRandomValues(cryptoRandom);
    let random = cryptoRandom % _arr.length;
    pickedIdx = random === _arr.length ? _arr.length - 1 : random;
    result.push(..._arr.splice(pickedIdx, 1));
  }
  return result;
};

/**
 * 結束頁面
 * @params {text} string 結束文字
 * @params {EndPageStyle} obj 結束頁面樣式
 * @params {EndPagePic} link 結束頁面圖檔
 * @params {ButtonSentence} string 結束按鈕文字
 * @params {EnableRedirect} bool 是否轉導
 * @params {RedirectUrl} link 轉導連結
 * @return {SpecialPage}
 */
function SpecialPage(props) {
  let img, text, buttonText;
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const url = typeof window !== "undefined" ? window.location.href : "";
  let isTestData = false;
  if (typeof window !== "undefined") {
    isTestData = queryString.parse(window.location.search).test;
  }
  let propsText = props.text && props.text ? props.text : "感謝您的參與！";

  console.log("ttt", isTestData);

  const initialValue = [
    {
      type: "align-center",
      children: [
        {
          type: "paragraph",
          children: [
            {
              text: propsText,
              size: "24",
              bold: true,
              color: "#0075d3",
            },
          ],
        },
      ],
    },
  ];

  const initialEndPage = {
    EndPagePic: "/images/EndPage_Web.png", //"https://hamipoint.cht.com.tw/images/cht-logo.png",
    EndPageStyle: initialValue, //endPageData.EndPageStyle
    ButtonSentence: "關閉視窗",
    EnableRedirect: true,
    RedirectUrl: "https://www.cht.com.tw/home/consumer",
  };

  if (props.hasOwnProperty("EndPageStyle")) {
    text = props.EndPageStyle;
    text = RichTextUtils.catText(text);
    img = props.EndPagePic;
    buttonText = props.ButtonSentence;
  } else {
    text = initialEndPage.EndPageStyle;
    img = initialEndPage.EndPagePic;
    buttonText = initialEndPage.ButtonSentence;
  }

  return (
    <>
      {!props.hasOwnProperty("EndPageStyle") ? (
        <Wrapper>
          <FinishPage
            isTestData={isTestData}
            status={props.status}
            title={text}
            topImg={img}
            btnText={buttonText}
            btnLink={
              props.EnableRedirect
                ? props.RedirectUrl
                : initialEndPage.RedirectUrl
            }
            preview={url.indexOf("preview") > 0}
          >
            {props.EndPageStyle ? (
              <Slate editor={editor} value={JSON.parse(props.EndPageStyle)}>
                <RichTextEditor readOnly={true} />
              </Slate>
            ) : (
              <Slate editor={editor} value={text}>
                <RichTextEditor readOnly={true} />
              </Slate>
            )}
          </FinishPage>
        </Wrapper>
      ) : props && (props.text || props.EndPageStyle) ? (
        <Wrapper>
          <FinishPage
            isTestData={isTestData}
            status={props.status}
            title={text}
            topImg={img}
            btnText={buttonText}
            btnLink={
              props.EnableRedirect
                ? props.RedirectUrl
                : initialEndPage.RedirectUrl
            }
            preview={url.indexOf("preview") > 0}
          >
            {props.EndPageStyle ? (
              <Slate editor={editor} value={JSON.parse(props.EndPageStyle)}>
                <RichTextEditor readOnly={true} />
              </Slate>
            ) : (
              <Slate editor={editor} value={text}>
                <RichTextEditor readOnly={true} />
              </Slate>
            )}
          </FinishPage>
        </Wrapper>
      ) : (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            zIndex: "999999",
          }}
        >
          <Ant.Spin />
        </div>
      )}
    </>
  );
}

/**
 * 歡迎頁面
 * @params {text} string 歡迎文字
 * @params {WelcomePageStyle} obj 歡迎頁面樣式
 * @return {WelcomeTypePage}
 */
function WelcomeTypePage(props) {
  let img, text, buttonText;
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const url = typeof window !== "undefined" ? window.location.href : "";
  let propsText = props.text && props.text ? props.text : "感謝您的參與！";
  let survey = props.survey;

  const initialValue = [
    {
      type: "align-center",
      children: [
        {
          type: "paragraph",
          children: [
            {
              text: propsText,
              size: "24",
              bold: true,
              color: "#0075d3",
            },
          ],
        },
      ],
    },
  ];

  const initialWelcomePage = {
    WelcomePagePic: "/images/EndPage_Web.png",
    WelcomePageStyle: initialValue,
    ButtonSentence: "前往問卷",
  };

  if (props && props.hasOwnProperty("WelcomePageStyle")) {
    text = props.WelcomePageStyle;
    text = RichTextUtils.catText(text);
    img = props.WelcomePagePic;
    buttonText = props.ButtonSentence;
  } else {
    text = initialWelcomePage.WelcomePageStyle;
    img = initialWelcomePage.WelcomerPagePic;
    buttonText = initialWelcomePage.ButtonSentence;
  }

  return (
    <>
      {props &&
      props.welcomePageData !== undefined &&
      !props.hasOwnProperty("WelcomePageStyle") ? (
        <Wrapper>
          <WelcomePage
            title={text}
            topImg={img}
            btnText={buttonText}
            survey={survey}
            preview={url.indexOf("preview") > 0}
          >
            {/* {props.WelcomePageStyle ? (
            <Slate editor={editor} value={JSON.parse(props.WelcomePageStyle)}>
              <RichTextEditor readOnly={true} />
            </Slate>
          ) : (
            <Slate editor={editor} value={text}>
              <RichTextEditor readOnly={true} />
            </Slate>
          )} */}
          </WelcomePage>
        </Wrapper>
      ) : props &&
        props.welcomePageData !== undefined &&
        (props.text || props.WelcomePageStyle || props.PrivacyContent) ? (
        <Wrapper>
          <WelcomePage
            title={text}
            topImg={img}
            btnText={buttonText}
            survey={survey}
            preview={url.indexOf("preview") > 0}
            welcomePageData={props.welcomePageData}
          >
            {/* {props.WelcomePageStyle ? (
              <Slate editor={editor} value={JSON.parse(props.WelcomePageStyle)}>
                <RichTextEditor readOnly={true} />
              </Slate>
            ) : (
              <Slate editor={editor} value={text}>
                <RichTextEditor readOnly={true} />
              </Slate>
            )} */}
          </WelcomePage>
        </Wrapper>
      ) : (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            zIndex: "999999",
          }}
        >
          <Ant.Spin />
        </div>
      )}
    </>
  );
}

const STATUS = {
  QUESTIONNAIRE: "QUESTIONNAIRE",
  FORCE_QUIT: "FORCE_QUIT",
  ENDING: "ENDING",
  FULL: "FULL",
  PARTIALLY_FULL: "PARTIALLY_FULL",
  EXPIRED: "EXPIRED",
  ERROR: "ERROR",
  // 2022-08-03 ALVIN 新增歡迎頁面
  WELCOME: "WELCOME",
};

/**
 * 結束頁面
 * @params {externalLink} bool 是否有額外連結
 * @params {ifTemplate} bool 是否為樣板
 * @params {provideType} int 正式 or 測試
 * @params {passByParameter} string 填答傳遞參數
 * @return {Questionnaire}
 */
const Questionnaire = ({
  externalLink,
  ifTemplate,
  provideType,
  passByParameter,
  ...props
}) => {
  const jumpOptFullIndex = useRef(null);
  const isOptFull = jumpOptFullIndex.current
    ? jumpOptFullIndex.current >= 0
    : false;

  const isTestData = queryString.parse(window.location.search).test;
  const [survey, setSurvey] = useState(null);
  const [logicList, setLogicList] = useState([]);
  const [endingPageData, setEndingPageData] = useState([]);
  const [welcomePageData, setWelcomePageData] = useState();
  const [loading, setLoading] = useState(true);
  const [endPageLoading, setEndPageLoading] = useState(true);
  const [pageIdx, setPageIdx] = useState(0);
  const [answers, setAnswers] = useState(null);
  const [showValidationUi, setShowValidationUi] = useState(false);
  const [logicMatcher, setLogicMatcher] = useState(new LogicMatcher());
  const [status, setStatus] = useState(STATUS.QUESTIONNAIRE);
  const [timePeriod, setTimePeriod] = useState(0);
  const [scrollMove, setScrollMove] = useState(false);
  const [layoutType, setLayoutType] = useState("desktop");
  const [noPass, setNoPass] = useState(true);
  const [showNoPassMsg, setShowNoPassMsg] = useState(null);
  const url = typeof window !== "undefined" ? window.location.href : "";
  const [btnIsDisabled, setBtnIsDisabled] = useState(false);
  const [theme, setTheme] = useState();
  const [limitData, setLimitData] = useState();

  const systemTheme = survey?._data?._themeProps?.Theme ?? {};

  // 初始化問卷 & Layout
  useEffect(() => {
    if (!timePeriod) {
      setTimePeriod(Date.now());
    }
    setTimeout(() => {
      setBtnIsDisabled(false);
    }, 500);
    window.innerWidth >= 824
      ? setLayoutType("desktop")
      : setLayoutType("mobile");
  });

  useEffect(() => {
    if (layoutType === "mobile" && status !== STATUS.WELCOME) {
      document.addEventListener("scroll", _ =>
        window.scrollY > 34 ? setScrollMove(true) : setScrollMove(false)
      );
    }
  }, [layoutType]);

  useEffect(() => {
    // API 拉問卷資料
    async function fetchData() {
      setLoading(true);
      setEndPageLoading(true);
      try {
        const id = queryString.parse(props.location.search).id;
        let survey = null;
        let endingPageJson = null;
        // 2022-08-03 ALVIN 新增 welcome page data
        let welcomePageJson = null;
        let logicList = null;
        // let questionOtherFlag = null;

        if (!ifTemplate) {
          if (externalLink === true) {
            //RenderSurvey API
            let externalData = await Survey.Actions.fetchExternalSurvey(
              id,
              true
            );
            // let externalDataOtherFlag = await Survey.Actions.fetchExternalSurvey(
            //   id,
            //   true
            // );
            // questionOtherFlag = externalDataOtherFlag.survey.questions;
            // survey = 從externalData(問題的東西)整理出來的資料
            if (externalData.code === "200") {
              survey = externalData.survey;
              logicList = externalData.logicList;
              endingPageJson = externalData.endPage;

              // 2022-08-03 ALVIN 暫時以end page data 代替
              // TODO: 補上welcome data
              welcomePageJson = externalData.welcomePage;
            } else if (
              externalData.code === "406" ||
              externalData.code === "-1"
            ) {
              window.localStorage.removeItem("token");
              window.localStorage.removeItem("verifyInfo");
              window.location.reload();
            }
            // survey = externalData.survey;
            // logicList = externalData.logicList;
            // questionnaireStatus = externalData.endPage;

            // survey = await Survey.Actions.fetchById(id);
            // questionnaireStatus = await Survey.Actions.fetchQuestionnaireStatusById(
            //   id
            // );
            // logicList = await Survey.Actions.fetchLogicList(id);
            // logicList = 從externalData(邏輯的東西)整理出來的資料
          } else {
            //預覽 編輯
            let fetchSurvey = await Survey.Actions.fetchById(id, true);
            survey = fetchSurvey.survey;
            endingPageJson = await Survey.Actions.fetchEndingPageById(id);

            // 2022-08-03 ALVIN 暫時用end data 替代
            // TODO: 補上welcome data
            welcomePageJson = await Survey.Actions.fetchWelcomePageById(id);
            logicList = await Survey.Actions.fetchLogicList(id, props.survey);
          }
        } else {
          // 範本預覽
          //預覽 編輯
          let fetchSurvey = await Survey.Actions.fetchById(id, true);
          survey = fetchSurvey.survey;
          endingPageJson = await Survey.Actions.fetchEndingPageById(id);

          // 2022-08-03 ALVIN 暫時用end data 替代
          // TODO: 補上welcome data
          welcomePageJson = await Survey.Actions.fetchWelcomePageById(id);
          logicList = await Survey.Actions.fetchLogicList(id, props.survey);
        }
        // 整理問題與選項
        survey.questions.map((q, idx) => {
          if (q.type !== "PAGE") {
            let _options = q.options
              .slice()
              .map((opt, idx) => ({ ...opt, idx }));
            let options = [];
            if (q.advanced?.random) {
              const excludeOthers = q.advanced.random.excludeOthers;
              if (excludeOthers) {
                options = shuffle(_options.filter(o => o.type !== "others"));
                options.push(..._options.filter(o => o.type === "others"));
              } else {
                options = shuffle(_options);
              }
            } else {
              options = _options.sort((a, b) =>
                a.type === "others" ? 1 : b.type === "others" ? -1 : 0
              );
            }
            survey.questions[idx].arrOptions = options;
          }
        });
        // 設定問卷頁面相關資料
        setSurvey(survey);
        setLogicList(logicList);
        setAnswers(
          survey.questions.map((q, idx) => ({
            answer: null,
            questionId: q.id,
            optionsInfo: q.options,
          }))
        );
        logicMatcher.setSurveyData({ survey, logicList, answers });
        logicMatcher.answers = answers;
        // }
        setEndingPageData(endingPageJson);
        // 結束頁讀取完才確認顯示結束頁，不然會跳出預設圖片
        setEndPageLoading(false);
        setWelcomePageData(welcomePageJson);

        // 問卷額滿
        if (endingPageJson.full) {
          setStatus(STATUS.FULL);
        }
        if (welcomePageJson.full) {
          setStatus(STATUS.FULL);
        }

        // 2022-05-29 : 企業主題
        const { theme } = survey.themeConfig?.backgroundTheme;
        setTheme(theme);
      } catch (ex) {
        // alert("系統異常，請洽系統管理員");
      }
      setLoading(false);
    }

    try {
      // 依據 query string 的 status 狀態改變頁面狀態
      const lstatus = queryString.parse(props.location.search).status;
      if (lstatus === "ending" && externalLink) {
        setStatus(STATUS.ENDING);
        setLoading(false);
      } else if (lstatus === "ending" && !externalLink) {
        setStatus(STATUS.ENDING);
        fetchData();
        setLoading(false);
      }
      // 2022-08-03 ALVIN 新增歡迎頁
      else if (lstatus === "welcome" && externalLink) {
        setStatus(STATUS.WELCOME);
        setLoading(false);
      } else if (lstatus === "welcome" && !externalLink) {
        setStatus(STATUS.WELCOME);
        fetchData();
        setLoading(false);
      } else if (lstatus === "force_quit") {
        setStatus(STATUS.FORCE_QUIT);
        setLoading(false);
      } else if (lstatus === "full") {
        setStatus(STATUS.FULL);
        setLoading(false);
      } else if (lstatus === "partially_full") {
        setStatus(STATUS.PARTIALLY_FULL);
        setLoading(false);
      } else if (lstatus === "expired") {
        setStatus(STATUS.EXPIRED);
        setLoading(false);
      } else if (lstatus === "error") {
        setStatus(STATUS.ERROR);
        setLoading(false);
      } else {
        setStatus(STATUS.QUESTIONNAIRE);
        fetchData();
      }
    } catch (err) {
      //
    }
  }, [props.location, logicMatcher, status]);

  // 取的交叉選項分析資料
  useEffect(() => {
    fetchLimitStatisticData();
  }, [pageIdx]);

  // 交叉選項回覆數量，預設只有真實的問卷才會有此行為
  // 預覽不呈現
  async function fetchLimitStatisticData(cb = () => {}) {
    if (!ifTemplate) {
      if (externalLink === true) {
        const id = queryString.parse(props.location.search).id;
        const res = await Survey.Actions.fetchOutSideLimitStatisticData(
          id,
          provideType,
          // 數據來源 1:測試環境;2:正式環境<
          Number(window.localStorage.getItem("SEnv")) === 1
        );

        const code = _.get(res, "code", null);
        if (code === "200") {
          const { data: _limitData } = res;
          logicMatcher.setLimitData(_limitData);
          setLimitData(_limitData);
          setLogicMatcher(logicMatcher);
        }
        cb();
      }
    }
  }

  // 新增監控寬度事件，用於切換矩陣題版型
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 824) setLayoutType("desktop");
      else setLayoutType("mobile");
    }

    window.addEventListener("resize", handleResize);
  }, []);

  // 子頁面更新填答問題 callback func
  async function updateAnswer(idx, answerProps) {
    const answersClone = [...answers];
    answersClone[idx] = {
      ...answers[idx],
      ...answerProps,
    };
    const question = survey.questions[idx];
    // 判斷如果是有設定交叉數量上限的題目，則點選選項時，需重新取得數量上限資料
    let needReacquire = logicMatcher.reacquireStatistics(
      question,
      answersClone[idx]
    );
    if (needReacquire) fetchLimitStatisticData();
    logicMatcher.answers = answersClone;
    logicMatcher.match({ question, answer: answersClone[idx], answers });
    setAnswers(answersClone);
  }

  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          zIndex: "999999",
        }}
      >
        <Ant.Spin />
      </div>
    );
  }

  if (
    status === STATUS.ENDING &&
    endingPageData &&
    !loading &&
    !endPageLoading
  ) {
    return <SpecialPage {...endingPageData} />;
  } else if (status === STATUS.WELCOME && !loading) {
    return (
      <WelcomeTypePage
        {...welcomePageData}
        welcomePageData={welcomePageData}
        survey={survey}
      />
    );
  } else if (status === STATUS.FORCE_QUIT && !loading) {
    return <SpecialPage text="感謝您的熱情參與！" />;
  } else if (status === STATUS.FULL && !loading) {
    return <SpecialPage text="本問卷已額滿" />;
  } else if (status === STATUS.PARTIALLY_FULL && !loading) {
    return (
      <SpecialPage
        status="partially-full"
        text={`您選擇的選項已經額滿\n返回問卷重新作答`}
      />
    );
  } else if (status === STATUS.EXPIRED && !loading) {
    return <SpecialPage text="本問卷已截止" />;
  } else if (status === STATUS.ERROR && !loading) {
    return <ErrorPage {...props} />;
  } else if (!survey || loading || endPageLoading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          zIndex: "999999",
        }}
      >
        <Ant.Spin />
      </div>
    );
  }

  const pages = survey.oneQuestionPerPage
    ? buildOneQuestionPerPagePager(survey)
    : buildPager(survey);
  const page = pages[pageIdx];
  // 問卷分頁前跳至後頁
  function findTargetPage(checkPage, type) {
    if (checkPage === 0 && type === "prev") {
      return checkPage;
    } else if (checkPage === pages.length - 1 && type === "next") {
      return checkPage;
    } else {
      let allJumped = true;
      for (
        var jumpedIdx = pages[checkPage].begin;
        jumpedIdx < pages[checkPage].end;
        jumpedIdx++
      ) {
        if (
          !logicMatcher.shouldJumpOver(
            survey.questions[jumpedIdx],
            answers[jumpedIdx]
          )
        ) {
          allJumped = false;
          break;
        }
      }
      if (allJumped) {
        if (type === "next") {
          return findTargetPage(checkPage + 1, type);
        } else {
          return findTargetPage(checkPage - 1, type);
        }
      } else {
        return checkPage;
      }
    }
  }
  // 問卷分頁下一頁，如果已經結束就跳至結束
  function goToNextPage() {
    if (isOptFull) {
      goOptPartiallyFullPage();
      return;
    }

    updateAnswer(page.end, { answer: null }, true);
    setBtnIsDisabled(true);
    if (!logicMatcher.validateAnswers(page, survey, answers)) {
      setShowValidationUi(true);
      return;
    }
    setShowValidationUi(false);

    if (logicMatcher.shouldForceQuit()) {
      try {
        submit(noPass);
        const id = queryString.parse(props.location.search).id;

        // 2022-05-23 : 開啟預覽問卷的送出顯示結束頁面行為
        // externalLink == false，表示為預覽
        if (externalLink)
          navigate(`${props.location.pathname}?id=${id}&status=force_quit`);
        else
          navigate(
            `${props.location.pathname}?id=${id}&status=force_quit&preview=true`
          );
      } catch (err) {}
      return;
    }

    let targetPage = findTargetPage(pageIdx + 1, "next");
    if (targetPage < pages.length) {
      setPageIdx(targetPage);
    }

    // 回到頂部
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  // 問卷分頁上一頁
  function goToPrevPage() {
    setBtnIsDisabled(true);
    if (pageIdx > 0) {
      let ansIdx;
      for (ansIdx = page.begin; ansIdx < page.end; ansIdx++) {
        answers[ansIdx].answer = null;
        logicMatcher.cancelLogic({
          question: survey.questions[ansIdx],
          answer: answers[ansIdx],
        });
      }
      let targetPage = findTargetPage(pageIdx - 1, "prev");
      setPageIdx(targetPage);
    }
    // 回到頂部
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  // 前往額滿頁面
  const goOptFullPage = () => {
    const id = queryString.parse(props.location.search).id;
    if (externalLink) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("verifyInfo");
      navigate(`${props.location.pathname}?id=${id}&status=full`);
    }
  };
  // 前往額滿頁面
  const goOptPartiallyFullPage = () => {
    const id = queryString.parse(props.location.search).id;
    if (externalLink) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("verifyInfo");
      navigate(
        `${props.location.pathname}?id=${id}&status=partially_full${
          isTestData ? "&test=true" : ""
        }`
      );
    }
  };
  // 確認是否前往額滿頁面
  const shouldGoToPartiallyFullPage = () => {
    let optFullList = [];
    // 抓出交叉選項如果已經關閉的選項
    logicMatcher.limitData.Options.filter(opt => opt.IsClosed).forEach(opt => {
      optFullList.push(opt.OptionList);
    });

    // 邏輯題組抓出答案不為空
    let ansIdList = [];
    logicMatcher.answers.forEach(ans => {
      if (ans.answer === null) {
        return;
      } else if (Array.isArray(ans.answer)) {
        ans.answer.forEach(ansIdx => {
          ansIdList.push(ans.optionsInfo[ansIdx]?.id);
        });
      } else {
        ansIdList.push(ans.optionsInfo[ans.answer]?.id);
      }
    });

    let invalid = false;
    // 比對交叉選項已滿的選項是否與邏輯題組重疊，是的話就直接結束
    optFullList.forEach(l => {
      const filterList = l.filter(o => !ansIdList.includes(o));
      if (filterList.length === 0) {
        invalid = true;
      }
    });
    return invalid;
  };
  /**
   * 送出填答問卷
   * @params {noPass} bool 是否跳過邏輯檢核
   * @return {empty}
   */
  async function submit(noPass) {
    if (!survey._data.validationCode) noPass = false;
    setShowNoPassMsg(noPass);
    if (!logicMatcher.validateAnswers(page, survey, answers, noPass)) {
      setShowValidationUi(true);
      return;
    }
    setShowValidationUi(false);

    if (logicMatcher.limitData !== null) {
      if (logicMatcher.limitData.IsAllClosed) {
        goOptFullPage();
        return;
      }
      if (
        !logicMatcher.limitData.IsAllClosed &&
        (shouldGoToPartiallyFullPage() || isOptFull)
      ) {
        goOptPartiallyFullPage();
        return;
      }
    }

    let data = {
      SurveyId: survey.id,
      ProvideType: provideType || 0,
      ExtenField: "",
      VerifyInfo: window.localStorage.getItem("verifyInfo") || "",
      ParameterInfo: passByParameter || "",
      Device: window.navigator.userAgent,
      ForceEnd: logicMatcher.shouldForceQuit(),
      TimePeriod: moment(timePeriod).format("YYYY-MM-DD HH:mm:ss.SSS"),
      SubmitTime: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss.SSS"),
      Env: Number(window.localStorage.getItem("SEnv")),
      AnswerList: [],
    };

    let answerDefault = {
      QuestionId: "",
      OptionId: "",
      MatrixField: "",
      BlankAnwer: "",
    };

    // 子母題縮排: 答案整理，如果母題對應選項並未作答，則不送出子題答案
    survey.questions.map((item, index) => {
      if (REAL_QUESTIONS.indexOf(item.type) !== -1) {
        if (item.isChild) {
          // 取得母題
          const parentQuestion = item.getParentQuestion;

          // 現有Function，取得母題選項是否有作答
          // true: 未作答
          // false: 已作答
          const children = logicMatcher.shouldChildren(
            item,
            parentQuestion,
            answers
          );

          // 如果為子題，且母題尚未作答，則不送出Answer
          if (children) return;
        }
        switch (item.type) {
          case "SINGLE_CHOICE": {
            if (answers[index].answer !== null) {
              let submitAnswer = JSON.parse(JSON.stringify(answerDefault));
              submitAnswer.QuestionId = item.id;
              submitAnswer.OptionId = item.options[answers[index].answer].id;
              if (
                answers[index].othersValue &&
                (item.options[answers[index].answer].value === "其他" ||
                  item.options[answers[index].answer].value === "其它")
              ) {
                submitAnswer.BlankAnwer = answers[index].othersValue;
              }
              data.AnswerList.push(submitAnswer);
            }
            break;
          }
          case "MULTIPLE_CHOICE": {
            if (
              Array.isArray(answers[index].answer) &&
              answers[index].answer.length > 0
            ) {
              answers[index].answer.map(answerItem => {
                let submitAnswer = JSON.parse(JSON.stringify(answerDefault));
                submitAnswer.QuestionId = item.id;
                submitAnswer.OptionId = item.options[answerItem].id;
                if (
                  answers[index].othersValue &&
                  (item.options[answerItem].value === "其他" ||
                    item.options[answerItem].value === "其它")
                ) {
                  submitAnswer.BlankAnwer = answers[index].othersValue;
                }
                data.AnswerList.push(submitAnswer);
              });
            }
            break;
          }
          case "MATRIX": {
            if (
              Array.isArray(answers[index].answer) &&
              answers[index].answer.length > 0
            ) {
              answers[index].answer.map(answerItem => {
                let submitAnswer = JSON.parse(JSON.stringify(answerDefault));
                submitAnswer.QuestionId = item.id;
                submitAnswer.MatrixField = item.rows[answerItem[0]];
                submitAnswer.OptionId = item.options[answerItem[1]].id;
                data.AnswerList.push(submitAnswer);
              });
            }
            break;
          }
          case "FILL_IN":
          case "BASIC_INFO": {
            if (answers[index].answer) {
              let submitAnswer = JSON.parse(JSON.stringify(answerDefault));
              submitAnswer.QuestionId = item.id;
              submitAnswer.BlankAnwer = answers[index].answer;
              data.AnswerList.push(submitAnswer);
            }
            break;
          }
          case "NUMERICAL": {
            if (
              Array.isArray(answers[index].answer) &&
              answers[index].answer.length > 0
            ) {
              answers[index].answer.map(answerItem => {
                let submitAnswer = JSON.parse(JSON.stringify(answerDefault));
                submitAnswer.QuestionId = item.id;
                submitAnswer.OptionId = answerItem.id;
                submitAnswer.BlankAnwer = answerItem.value;
                data.AnswerList.push(submitAnswer);
              });
            }
            break;
          }
          default:
            break;
        }
      }
    });

    const locationSearch = _.get(props, "location.search");
    const query = queryString.parse(locationSearch);

    if (externalLink) {
      let resp = await Survey.Actions.submitAnswer(data, false);

      if (typeof resp === "string" || resp instanceof String) {
        resp = JSON.parse(resp);
      }

      const code = _.get(resp, "code", "101");
      const message = _.get(resp, "message", "");
      if (code === "406") {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("verifyInfo");
        setStatus(STATUS.ENDING);
      }

      if (code === "602" || code === "9004" || code === "9602") {
        const id = queryString.parse(props.location.search).id;
        setStatus(STATUS.FULL);
        navigate(`${props.location.pathname}?id=${id}&status=full`);
        return;
      }

      if (code !== "200") {
        const id = queryString.parse(props.location.search).id;
        setStatus(STATUS.ERROR);
        navigate(
          `${props.location.pathname}?id=${id}&status=error&code=${code}&message=${message}`
        );
        return;
      }
      /**
       * 9001 此份問卷未開放
       * 9003 此份問卷已經截止
       * 9004 此份問卷已額滿
       * 9005 似乎遇到錯誤...
       * 9007 似乎遇到錯誤...
       * 9202 問卷不存在
       * 9601 資格不符
       * 9602 本問卷已額滿，謝謝您的熱情參與
       * 9605 似乎遇到錯誤...
       * 9500 似乎遇到錯誤...
       * HTTP 似乎遇到錯誤...
       */
      // 填寫站台送出異常時 : 需導至404
      // http://localhost:8000/q/?id=d8742729-e169-ec11-b349-00155d13601c&ProvideType=1&test=true
      /** 先註解此段，待確認需要給用戶看到的指定訊息之後再檔 */
      // if (code !== "200") {
      //   query.code = code;
      //   query.message = _.get(resp, "message", "似乎遇到錯誤...");
      //   navigate(`/error?${queryString.stringify(query)}`);
      //   return;
      // }
    }

    // 2022-05-23 : 開啟預覽問卷的送出顯示結束頁面行為
    // externalLink == false，表示為預覽
    if (logicMatcher.shouldForceQuit()) {
      try {
        if (externalLink) {
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("verifyInfo");
          // Survey.Actions.setExternalLocalStorageToken(false);
        }

        const id = queryString.parse(props.location.search).id;
        if (externalLink)
          navigate(`${props.location.pathname}?id=${id}&status=force_quit`);
        else {
          // 預覽模式
          // 修改為顯示tab
          // navigate(
          //   `${props.location.pathname}?id=${id}&status=force_quit&preview=true`
          // );
          window.localStorage.setItem("defaultTab", "end");
          top.location.reload();
          // top.location.replace(`${top.location.href}&tab=end`);
        }
      } catch (err) {}
      return;
    }

    // 2022-05-23 : 開啟預覽問卷的送出顯示結束頁面行為
    // externalLink == false，表示為預覽
    if (survey.endingDisplay) {
      try {
        if (externalLink) {
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("verifyInfo");
          // Survey.Actions.setExternalLocalStorageToken(false);
        }

        const id = queryString.parse(props.location.search).id;
        if (externalLink) {
          navigate(`${props.location.pathname}?id=${id}&status=ending`);
        } else {
          // navigate(
          //   `${props.location.pathname}?id=${id}&status=ending${
          //     externalLink ? `` : `&preview=true`
          //   }`
          // );
          // 預覽模式
          // 修改為顯示tab
          window.localStorage.setItem("defaultTab", "end");
          // eslint-disable-next-line no-restricted-globals
          top.location.reload();
          // top.location.replace(`${top.location.href}&tab=end`);
        }
      } catch (err) {}
    } else {
      if (externalLink)
        window.location.replace("https://www.cht.com.tw/home/consumer");
      else {
        // eslint-disable-next-line no-restricted-globals
        top.location.replace("https://www.cht.com.tw/home/consumer");
      }
    }
  }
  /**
   * 送出填答問卷
   * @params {answers} array 答案
   * @params {questionsInst} array 題目
   * @params {page} int 頁面
   * @params {type} string percentage or others 資料呈現方式
   * @return {obj} percent & display
   */
  function _getAnswerProgressCount(
    answers,
    questionsInst,
    page,
    type = "percentage"
  ) {
    let unAnswered = 0;
    let realAnswerList = answers.slice();
    // filter out unreal-questions in answers
    realAnswerList = realAnswerList.filter((a, idx) =>
      questionsInst[idx].isRealQuestion()
    );

    for (let { answer } of realAnswerList.reverse()) {
      if (answer === null) {
        unAnswered++;
      } else if (Array.isArray(answer) && answer.length === 0) {
        unAnswered++;
      } else if (typeof answer === "string" && answer.length === 0) {
        unAnswered++;
      } else {
        break;
      }
    }

    let prePageQuestion = 0;
    if (page.begin) {
      for (const q of questionsInst.slice(page.begin, page.end)) {
        if (q.isRealQuestion()) {
          prePageQuestion = q.getIdxDisplay() - 1;
          break;
        }
      }
    }
    let answeredProgressCount = Math.max(
      realAnswerList.length - unAnswered,
      prePageQuestion
    );
    const percent =
      Math.round((answeredProgressCount * 100) / realAnswerList.length) / 100;

    if (isNaN(percent)) {
      return {
        percent: 0,
        display: "0%",
      };
    }

    if (type === "percentage") {
      return { percent, display: `${Math.round(percent * 100)}%` };
    }

    return {
      percent,
      display: `${answeredProgressCount} / ${realAnswerList.length} 個問題`,
    };
  }

  const {
    percent: progressPercent,
    display: progressDisplay,
  } = _getAnswerProgressCount(
    answers,
    survey.questions,
    page,
    survey.progressDisplayDetail?.display
  );
  const themeObj = _.get(survey, "_data._themeProps.Theme.theme", {});
  console.debug("_data._themeProps.Theme.theme", themeObj);
  console.debug("JSON String", JSON.stringify(themeObj));

  return (
    !loading &&
    survey && (
      <Wrapper
        preview={url.indexOf("preview") > 0}
        survey={survey}
        style={{
          fontFamily: survey.themeConfig.Font,
        }}
      >
        <GlobalStyle theme={survey.themeConfig?.backgroundColor} />
        <Helmet>
          <title>{survey.title}</title>
        </Helmet>
        <div className="container">
          <div className="page">
            <div
              className={
                layoutType === "mobile"
                  ? scrollMove
                    ? pageIdx < 1
                      ? "scroll-move-true scroll-head"
                      : "scroll-head-mobile scroll-head show"
                    : pageIdx < 1
                    ? "scroll-head"
                    : "scroll-head hide"
                  : "scroll-head"
              }
            >
              <ColorBar style={{ height: 2 }} />
              <h1>{survey.title ? survey.title : "問卷調查"}</h1>
            </div>
            {survey?._data?._themeProps.HeadFlag ? (
              <DefaultQuestionnaireHeader
                className={
                  layoutType === "mobile"
                    ? scrollMove
                      ? pageIdx < 1
                        ? "scroll-move-true"
                        : "scroll-head-mobile"
                      : pageIdx < 1
                      ? ""
                      : "scroll-head-mobile"
                    : ""
                }
                style={{}}
              >
                {layoutType === "desktop" &&
                  (survey?._data?._themeProps.StyleType === 2 &&
                  survey?._data?._themeProps.DefHeaderPic ? (
                    <div className="main">
                      <img
                        src={survey?._data?._themeProps.DefHeaderPic}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div className="main">
                      <div className="ball">
                        <img src="/images/cht-logo-ball.png" />
                      </div>
                      <div className="logo">
                        <img src="/images/cht-logo-w.svg" alt="中華電信" />
                      </div>
                    </div>
                  ))}
                {layoutType === "mobile" &&
                  (survey?._data?._themeProps.StyleType === 2 &&
                  survey?._data?._themeProps.DefHeaderPhonePic ? (
                    <div className="main">
                      <img
                        className="header"
                        src={survey?._data?._themeProps.DefHeaderPhonePic}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div className="main">
                      <div className="ball">
                        <img src="/images/cht-logo-ball.png" />
                      </div>
                      <div className="logo">
                        <img src="/images/cht-logo-w.svg" alt="中華電信" />
                      </div>
                    </div>
                  ))}
                <ColorBar />
              </DefaultQuestionnaireHeader>
            ) : (
              <DefaultQuestionnaireHeader
                className={
                  layoutType === "mobile"
                    ? scrollMove
                      ? pageIdx < 1
                        ? "scroll-move-true"
                        : "scroll-head-mobile"
                      : pageIdx < 1
                      ? ""
                      : "scroll-head-mobile"
                    : ""
                }
                style={{}}
              >
                <ColorBar />
              </DefaultQuestionnaireHeader>
            )}
            {page && (
              <div className="page-container">
                <Title
                  className={
                    layoutType === "mobile"
                      ? scrollMove
                        ? pageIdx < 1
                          ? "scroll-move-true"
                          : "scroll-head-mobile"
                        : pageIdx < 1
                        ? ""
                        : "scroll-head-mobile"
                      : ""
                  }
                  theme={_.get(survey, "_data._themeProps.Theme.theme", {})}
                  style={{
                    paddingTop: survey?._data?._themeProps.HeadFlag
                      ? ""
                      : "20px",
                  }}
                >
                  {survey.title ? survey.title : "問卷調查"}
                </Title>
                {survey.questions.slice(page.begin, page.end).map((q, idx) => {
                  if (q.type === "PAGE") return null;
                  const questionElement = jumped => (
                    <QuestionDisplay
                      key={idx}
                      answers={answers}
                      options={q.arrOptions}
                      questionIdx={idx + page.begin}
                      question={q}
                      shouldDisableOption={logicMatcher.shouldDisableOption.bind(
                        logicMatcher
                      )}
                      shouldDisableOptionWithLimitText={logicMatcher.shouldDisableOptionWithLimitText.bind(
                        logicMatcher
                      )}
                      survey={survey}
                      previewMode={true}
                      answer={
                        jumped ? { answer: null } : answers[idx + page.begin]
                      }
                      updateAnswer={updateAnswer}
                      validate={showValidationUi}
                      theme={theme}
                      isChild={q.isChild}
                      {...props}
                    />
                  );
                  logicMatcher.shouldJumpOptionFull(
                    q,
                    answers[q.idx],
                    jumpOptFullIndex
                  );
                  if (
                    jumpOptFullIndex.current !== null &&
                    jumpOptFullIndex.current < idx
                  ) {
                    return "";
                  }
                  const jumped = logicMatcher.shouldJumpOver(q, answers[q.idx]);
                  if (jumped) {
                    return "";
                  }
                  if (q.isChild) {
                    return "";
                  }
                  return questionElement(false);
                })}
                {pageIdx === pages.length - 1 && survey.validationCode && (
                  <ValidationCodeInput
                    setNoPass={setNoPass}
                    setShowNoPassMsg={setShowNoPassMsg}
                    isError={showNoPassMsg}
                  />
                )}
                {showNoPassMsg && showValidationUi && (
                  <p className="errorMsg">驗證碼錯誤！</p>
                )}
              </div>
            )}
            <div className="page-footer">
              <Widget.FlexRow style={{ justifyContent: "center" }}>
                {pageIdx === pages.length - 1 ? (
                  <PrimaryButtonWrapper theme={systemTheme}>
                    <Ant.Button
                      className="primary-button"
                      onClick={e => {
                        if (!ifTemplate) {
                          if (externalLink === true) {
                            fetchLimitStatisticData(() => submit(noPass));
                          } else {
                            submit(noPass);
                          }
                        } else {
                          submit(noPass);
                        }
                      }}
                    >
                      送出問卷
                    </Ant.Button>
                  </PrimaryButtonWrapper>
                ) : (
                  <PrimaryButtonWrapper theme={systemTheme}>
                    <Ant.Button
                      className="primary-button"
                      onClick={goToNextPage}
                      disabled={pageIdx >= pages.length - 1}
                    >
                      下一頁
                    </Ant.Button>
                  </PrimaryButtonWrapper>
                )}
              </Widget.FlexRow>
              {survey.pageIdxDisplay && (
                // <Font.Small
                //   style={{
                //     color: Color.GreyBlack_45,
                //     textAlign: "center",
                //     position: "absolute",
                //     right: 0,
                //     left: 0,
                //     bottom: 0,
                //   }}
                // >
                <div className="page-number">第 {pageIdx + 1} 頁</div>
                // </Font.Small>
              )}
            </div>
          </div>
        </div>
        <div className="footer-content">
          <img className="footer-logo" src={chtLogo} />
        </div>
        <FooterBar>
          <div className="footerBar-wrapper">
            <PageBar
              top={survey.progressDisplayDetail.position === "top"}
              percent={progressPercent}
              theme={systemTheme}
            >
              {survey.progressDisplay && survey.progressDisplayDetail && (
                <>
                  <div className="progress-line-container">
                    <div className="progress-line" />
                  </div>
                  <div className="progress-label">{progressDisplay}</div>
                </>
              )}
            </PageBar>
            <PrevBtn theme={systemTheme}>
              <button
                disabled={btnIsDisabled}
                className={pageIdx <= 0 ? "hidden prev" : "prev"}
                onClick={goToPrevPage}
              >
                <div className="prev-icon">
                  <SvgIcon.Prev size={FontSize.largeTitle} />
                </div>
              </button>
              <button
                disabled={btnIsDisabled}
                className={pageIdx >= pages.length - 1 ? "hidden next" : "next"}
                onClick={goToNextPage}
              >
                <div className="next-icon">
                  <SvgIcon.Next size={FontSize.largeTitle} />
                </div>
              </button>
            </PrevBtn>
          </div>
        </FooterBar>
      </Wrapper>
    )
  );
};

const DefaultQuestionnaireHeader = styled.div`
  width: 800px;
  margin-left: -24px;
  margin-right: -24px;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: ${props => props.backgroundColor || Color.ChtBlue_5};
  opacity: 1;
  transition: opacity 0.05s ease-in;

  &.scroll-move-true {
    @media screen and (max-width: 823px) {
      opacity: 0;
    }
  }

  &.scroll-head-mobile {
    display: none;
  }

  @media screen and (max-width: 823px) {
    width: 100vw;
    margin-left: -8px;
    margin-right: -8px;
  }

  & > .main {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    @media screen and (max-width: 823px) {
      height: 50px;
    }

    img {
      width: 100%;
      height: auto;
    }
    // 修正手機版的圖片沒辦法填滿整個main造成有底色的問題
    img.header {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & > .ball {
      width: 59px;
      height: 60px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      @media screen and (max-width: 823px) {
        height: 50px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    & > .logo {
      width: 100px;
      height: 28px;
      margin-right: 23px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  & > .bottom-bar {
    height: 4px;
    background-image: linear-gradient(
      to right,
      #209cff 0%,
      #0267b9 20%,
      #fcaf15 39%,
      #ff874d 60%,
      #96d34f 81%,
      #47d382 100%
    );
  }
`;

const ColorBar = styled.div`
  height: 4px;
  background-image: linear-gradient(
    to right,
    #209cff 0%,
    #0267b9 20%,
    #fcaf15 39%,
    #ff874d 60%,
    #96d34f 81%,
    #47d382 100%
  );
`;

const ThemeOverwriteCss = `
  --antd-wave-shadow-color: #5c9dd2;
  --theme-color: ${Color.ChtBlue_5};
  .ant-radio-checked .ant-radio-inner  {
    border-color: var(--theme-color);
  }

  .ant-radio-inner::after {
    background-color: var(--theme-color);
  }
  .ant-checkbox-checked .ant-checkbox-inner  {
    background-color: var(--theme-color);
    border-color: var(--theme-color);
  }

  .ant-checkbox-checked::after {
    border-color: var(--theme-color);
  }

  ::selection  {
    color: #fff;
    background: var(--theme-color);
  }

  .ant-input:focus, .ant-input-focused {
    box-shadow: 0 0 0 2px rgba(109, 190, 255, 0.2);
    border-color: var(--theme-color);
  }

  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: var(--theme-color);
  }

  .ant-radio-input:focus + .ant-radio-inner  {
    box-shadow: 0 0 0 3px rgba(109, 190, 255, 0.08);
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--theme-color);
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px rgba(109, 190, 255, 0.2);
  }

  .ant-input:hover {
    border-color: var(--theme-color);
  }
`;

const Wrapper = styled.div`
  /* 2022-08-03 ALVIN 暫時remark掉 */
  // ${ThemeOverwriteCss}
  min-height: 590px;
  margin: auto;

  * {
    font-family: ${props => props?.survey?.themeConfig?.Font} !important;
  }

  .footer-content {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;

    @media screen and (max-width: 823px) {
      padding-bottom: 48px;
    }
  }

  .page-number {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    display: block;
    line-height: 86px;
    height: 70px;
    padding-bottom: 16px;
    text-align: center;
    font-size: ${FontSize.caption}px;
    color: ${Color.GreyBlack_45};

    @media screen and (max-width: 823px) {
      line-height: 48px;
      height: 40px;
      padding-top: unset;
      padding-bottom: 8px;
    }
  }

  .scroll-head {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    display: none;
    background-color: ${Color.ChtBlue_5};

    &.scroll-move-true {
      @media screen and (max-width: 823px) {
        /* opacity: 1; */
        display: block;
      }
    }

    &.scroll-head-mobile {
      @media screen and (max-width: 823px) {
        &.show {
          /* opacity: 1; */
          display: block;
          position: fixed;
        }
      }
    }
    &.hide {
      display: block;
      position: relative;
      margin-left: -8px;
      margin-right: -8px;
      margin-bottom: 24px;
    }

    @media screen and (min-width: 824px) {
      display: none;
    }

    h1 {
      font-size: ${FontSize.body}px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57em;
      letter-spacing: 0.48px;
      color: #ffffff;
      padding: 7px 20px;
      margin-bottom: unset;
    }
  }

  .footer-logo {
    width: auto;
    height: 34px;
    margin: 60px auto;
    object-fit: contain;
    @media screen and (max-width: 823px) {
      height: 23px;
      margin: 30px auto 22px;
    }
  }

  .errorMsg {
    margin-top: 8px;
    margin-bottom: 8px;
    font-family: "Noto Sans TC", "Helvetica Neue", "Consolas";
    font-size: ${FontSize.caption}px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: ${Color.Red_6};
    position: relative;
    top: 0px;
    left: 12px;
  }

  & > .container {
    border-radius: 5px;
    
    & > .page {
      position: relative;
      width: 800px;
      /* min-height: calc(532px - 54px); */
      padding: 0 24px;
      margin-left: auto;
      margin-right: auto;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      overflow: hidden;
      background-color: ${props => props.backgroundColor || "#fff"};
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);

      @media screen and (min-width: 824px) {
        margin-top: 40px;
      }

      @media screen and (max-width: 823px) {
        width: 100%;
        box-shadow: unset;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        padding: 0 8px 0px 8px;
      }

      @media screen and (max-width: 767px) {
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
      }

      &.scroll-move-true {
        padding-top: 44px;
      }

      .page-container {
        min-height: calc(532px - 54px - 22px - 178px);

        @media screen and (max-width: 823px) {
          min-height: calc(532px - 54px - 14px - 120px);
        }
      }
    }
  }

  /* 2022-05-16: 新增問卷發生異常提示對話框 */
  /* 針對錯誤訊息，獨立的按鈕style */
  .button-color {
    border-color: #209cff;
    position: relative;
    transition: all 0.25s;
    
    &.ok {
      background-color: #209cff;
      color: #ffffff;
      &:hover {
        background-color: #53b3ff;
      }
      &:active {
        background-color: #0075d3;
      }
      &:focus {
        background-color: #0691ff;
      }
    }

    &.cancel {
      color: #209cff;
      &:hover {
        color: #53b3ff;
      }
      &:active {
        color: #0075d3;
      }
      &:focus {
        color: #0691ff;
      }
    }

    &:hover {
      border-color: #53b3ff;
    }
    &:active {
      border-color: #0075d3;
    }
    &:focus {
      border-color: #0691ff;
      &::after {
        position: absolute;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color: transparent;
        border: solid 1px #f4f6fc;
      }
    }
    &[disabled] {
      border-color: rgba(0, 0, 0, 0.15);
      background-color: rgba(0, 0, 0, 0.15);
      color: rgba(0, 0, 0, 0.45);
    }
  }
`;

const PrimaryButtonWrapper = styled.div`
  .primary-button {
    border-color: ${props => props.theme.button.primary};
    background-color: ${props => props.theme.button.primary};
    width: 240px;
    height: 48px;
    margin: 60px auto 70px;
    color: #ffffff;
    position: relative;
    font-size: ${FontSize.head}px;
    transition: all 0.25s;

    @media screen and (max-width: 823px) {
      width: 325px;
      height: 40px;
      margin: 40px auto;
    }

    &:hover {
      border-color: ${props => props.theme.button.secondary};
      background-color: ${props => props.theme.button.secondary};
    }

    &:active,
    &:focus {
      border-color: ${props =>
        themeColor[props.theme?.button.primary.toUpperCase()]
          ?.primaryBtnActive};
      background-color: ${props =>
        themeColor[props.theme?.button.primary.toUpperCase()]
          ?.primaryBtnActive};
    }

    &:focus {
      &::after {
        position: absolute;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color: transparent;
        border: solid 1px #f4f6fc;
      }
    }

    &[disabled] {
      border-color: rgba(0, 0, 0, 0.15);
      background-color: rgba(0, 0, 0, 0.15);
      color: rgba(0, 0, 0, 0.45);
    }
  }
`;

const FooterBar = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #fdfcfc;
  box-shadow: -1px 2px 4px 1px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 823px) {
    justify-content: flex-start;
    height: 48px;
  }
  .footerBar-wrapper {
    width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 823px) {
      width: 100%;
    }
  }
`;

const PrevBtn = styled.div`
  position: relative;
  padding-right: 36px;
  padding-left: 125px;
  width: 305px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .prev,
  .next {
    width: 64px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    cursor: pointer;
    border: unset;
    outline: none;
    background-color: ${props =>
      hexToRgba(props.theme.button.secondary, "0.2")};
    color: ${props => props.theme.button.primary};
    transition: all 0.25s;

    &.hidden {
      visibility: hidden;
    }

    .prev-icon,
    .next-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;

      svg {
        & path {
          fill: ${props => props.theme.button.primary};
        }
      }
    }

    &:hover {
      background-color: ${props => props.theme.button.secondary};

      svg {
        & path {
          fill: ${Color.White};
        }
      }
    }

    &:active {
      background-color: ${props => props.theme.button.primary};

      svg {
        & path {
          fill: ${Color.White};
        }
      }
    }

    &:focus {
      position: relative;
      background-color: ${props => props.theme.button.primary};

      svg {
        & path {
          fill: ${Color.White};
        }
      }

      &::after {
        position: absolute;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color: transparent;
        border: 1px solid #f4f6fc;
      }
    }

    &:disabled,
    &.disabled,
    &[disabled] {
      background-color: ${Color.GreyWhite_2};

      svg {
        & path {
          fill: ${Color.Black_25};
        }
      }
    }
  }

  @media screen and (max-width: 823px) {
    padding-right: 20px;
    padding-left: 21px;
    width: 149px;

    .prev,
    .next {
      width: 48px;
      height: 36px;
    }
  }
`;

const PageBar = styled.div`
  position: relative;
  padding-left: 36px;
  width: 495px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  @media screen and (max-width: 823px) {
    width: 226px;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 0 10px 12px;
  }

  & > .progress-line-container {
    width: 300px;
    height: 10px;
    margin: 25px 16px 25px 0;
    background: rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    overflow: hidden;

    @media screen and (max-width: 823px) {
      width: 214px;
      height: 8px;
      margin: 0 0 6px;
    }

    & > .progress-line {
      width: ${props => props.percent * 100}%;
      background-color: ${props => props.theme.button.primary};
      border-radius: 7px;
      height: 100%;
    }
  }

  & > .progress-label {
    width: 143px;
    height: 14px;
    line-height: 14px;
    font-size: ${FontSize.body}px;
    color: ${props => props.theme.button.primary};

    @media screen and (max-width: 823px) {
      font-size: ${FontSize.caption}px;
    }
  }
`;

const Title = styled.div`
  line-height: 42px;
  padding: 20px 12px 22px;
  font-size: ${props => props?.theme?.web?.title?.size}px;
  color: ${props => props?.theme?.web?.title?.color};
  opacity: 1;
  transition: opacity 0.2s ease-in;

  &.scroll-move-true {
    @media screen and (max-width: 823px) {
      opacity: 0;
    }
  }

  &.scroll-head-mobile {
    display: none;
  }

  @media screen and (max-width: 823px) {
    // 2022-05-27 : 企業版型
    font-size: ${props =>
      props?.theme?.mobile?.title?.size
        ? props.theme.mobile?.title.size
        : FontSize.largeTitle}px;
    color: ${props =>
      props?.theme?.mobile?.title?.color
        ? props.theme.mobile?.title.color
        : Color.ChtBlue_8};
    padding-bottom: 24px;
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => (props.theme ? props.theme : "f4faff")};
  }
`;

export default Questionnaire;
