import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import * as Ant from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { withReact, Slate } from "slate-react";
import { withHistory } from "slate-history";
import { createEditor } from "slate";
import { jsx } from "slate-hyperscript";
import { Helmet } from "react-helmet";
import { Color, FontSize } from "../Components/Widget";
import PrivacyDisplay from "../Components/PrivacyDisplay";
import RichTextEditor from "../Components/RichTextEditor";
import ReactGA from "react-ga4";
import chtLogo from "../images/CHTLogo_color.svg";
import * as Survey from "../Contexts/SurveyContext";

/**
 * 歡迎頁面 個資聲明 文案元件
 * @param {content} text 內文
 * @param {editor} editor slat createEditor
 * @param {text} text 按鈕文字
 * @return {歡迎頁面個資聲明文字區塊}
 */
const ContentDisplay = ({ content, editor, text }) => {
  let propsText = text
    ? text
    : "您好！感謝您參與此問卷調查，為瞭解客戶意見以提供更好服務，誠摯邀請您參加線上調查。您所填寫的資料我們僅會用於整體統計分析，不會揭露個人資訊，敬請安心填答。";
  const initialValue = [
    {
      type: "align-left",
      children: [
        {
          type: "paragraph",
          children: [
            {
              text: propsText,
              size: "20",
              bold: true,
              color: "#324250",
            },
          ],
        },
      ],
    },
  ];
  if (content !== undefined) {
    if (typeof content === "object") {
      return (
        <Slate editor={editor} value={content ?? initialValue}>
          <RichTextEditor readOnly={true} placeholder=" " />
        </Slate>
      );
    } else if (typeof content === "string") {
      return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
    }
  }
  return (
    <Slate editor={editor} value={text ?? initialValue}>
      <RichTextEditor readOnly={true} placeholder=" " />
    </Slate>
  );
};

/**
 * 歡迎頁面 個資聲明
 * @param {title} text 標題
 * @param {topImg} text 上方圖檔連結
 * @param {btnText} text 按鈕文字
 * @param {survey} object 問卷物件
 * @param {preview} bool 是否預覽
 * @param {welcomePageData} object 歡迎頁物件
 * @return {welcome page}
 */
const WelcomePage = ({
  title,
  topImg,
  btnText,
  btnLink,
  preview,
  survey,
  afterSubmit,
  surveyId,
  welcomePageData,
  ...props
}) => {
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const [agree, setAgree] = useState({
    first: undefined,
    second: undefined,
    firstTime: undefined,
  });
  const [welcomePageDisplay, setWelcomePageDisplay] = useState({});
  const [privacyInfo, setPrivacyInfo] = useState({});

  // 遞迴解析 artrubutes 回傳解析後的資料
  const deserialize = (el, markAttributes = {}) => {
    if (el.nodeType === Node.TEXT_NODE) {
      return jsx("text", markAttributes, el.textContent);
    } else if (el.nodeType !== Node.ELEMENT_NODE) {
      return null;
    }

    const nodeAttributes = { ...markAttributes };

    // define attributes for text nodes
    switch (el.nodeName) {
      case "strong":
        nodeAttributes.bold = true;
    }

    const children = Array.from(el.childNodes)
      .map(node => deserialize(node, nodeAttributes))
      .flat();

    if (children.length === 0) {
      children.push(jsx("text", nodeAttributes, ""));
    }

    switch (el.nodeName) {
      case "BODY":
        return jsx("fragment", {}, children);
      case "BR":
        return "\n";
      case "BLOCKQUOTE":
        return jsx("element", { type: "quote" }, children);
      case "P":
        return jsx("element", { type: "paragraph" }, children);
      case "A":
        return jsx(
          "element",
          { type: "link", url: el.getAttribute("href") },
          children
        );
      case "UL":
        return jsx("element", { type: "bulleted-list" }, children);
      case "LI":
        return jsx("element", { type: "list-item" }, children);
      case "H1":
        return jsx("element", { type: "header_one" }, children);
      case "H2":
        return jsx("element", { type: "header_two" }, children);
      default:
        return children;
    }
  };

  /**
   * 按下 個資同意確認時觸發
   * @returns
   */
  const onSubmit = async () => {
    if (!preview) {
      try {
        // 檢查是否有勾選個資同意
        if (!agree.first) {
          Ant.Modal.warning({
            title: "提示",
            content: "請確認閱讀個人資料告知聲明",
            okText: "關閉",
            onOk: () => {
              window.opener = null;
              window.open("", "_self");
              window.close();
            },
            icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
          });
        }

        const payload = {
          Agree: agree.second,
          Read: agree.first,
          SurveyId: surveyId || survey.id,
          PrivacyVersion: privacyInfo.version || 0,
        };

        // 把個資同意資訊存在localstorage
        const payloadJSON = JSON.stringify(payload);

        let resp = await Survey.Actions.submitPrivacy(payloadJSON, false);

        if (resp.code !== "200") {
          localStorage.setItem("privacy", payloadJSON);
        }
        // 填寫個資同意後執行callback
        afterSubmit();
      } catch (ex) {
        console.warn(ex);
      }
    } else {
      if (agree.first !== 1) return;
      // eslint-disable-next-line no-restricted-globals
      parent.location.href = `/preview/?id=${survey.id}`;
    }
  };

  useEffect(() => {
    ReactGA.initialize("G-WNJFKVQLF0");
    ReactGA.send("pageview");
  }, []);

  // 進入頁面時先將變數都設定進去 state
  useEffect(() => {
    if (survey) {
      setWelcomePageDisplay({
        title: survey.title,
        welcomePagePic: topImg,
        buttonSentence: btnText,
      });
    } else if (welcomePageData) {
      setWelcomePageDisplay({
        title: welcomePageData.Title,
        welcomePagePic: welcomePageData.WelcomePagePic,
        welcomePageStyle: welcomePageData.WelcomePageStyle
          ? JSON.parse(welcomePageData.WelcomePageStyle)
          : welcomePageData.WelcomePageStyle,
        buttonSentence: welcomePageData.ButtonSentence,
      });
    }
    if (welcomePageData) {
      var content = null;
      try {
        content = welcomePageData.PrivacyContent
          ? JSON.parse(welcomePageData.PrivacyContent)
          : welcomePageData.PrivacyContent;
      } catch (e) {}
      if (content == null) {
        content = welcomePageData.PrivacyContent;
      }
      setPrivacyInfo({
        content: content,
        version: welcomePageData.PrivacyVersion,
      });
    }
  }, [welcomePageData, survey]);

  return (
    <Wrapper>
      <Helmet>
        <title>{welcomePageDisplay.title}</title>
      </Helmet>
      <Header />
      <Main>
        <ColorBar />
        <MainTitleWrapper>
          <div className="main-title">{welcomePageDisplay.title}</div>
        </MainTitleWrapper>
        {welcomePageDisplay.welcomePagePic && (
          <div className="banner">
            <img src={welcomePageDisplay.welcomePagePic} alt="中華電信問卷" />
          </div>
        )}
        <PrivacyWrapper>
          <ContentDisplay
            content={
              welcomePageData?.WelcomePageStyle
                ? JSON.parse(welcomePageData.WelcomePageStyle)
                : undefined
            }
            editor={editor}
            text={props.title}
          />
          <Ant.Divider style={{ margin: "60px 0px 0px" }}></Ant.Divider>
          <HeaderTitle>個人資料蒐集告知聲明及同意</HeaderTitle>
          <div className="privacy-display-wrapper">
            <PrivacyDisplay privacyInfo={privacyInfo} isPreview />
          </div>
          <Ant.Row style={{ marginBottom: "8px" }}>
            <Ant.Typography.Text>
              您是否同意中華電信股份有限公司問卷調查服務之個人資料蒐集告知聲明
            </Ant.Typography.Text>
          </Ant.Row>
          <Ant.Row style={{ "margin-bottom": "16px" }}>
            <Ant.Radio.Group
              value={agree.first}
              onChange={e =>
                setAgree(state => ({
                  ...state,
                  first: e.target.value,
                  firstTime:
                    e.target.value == 1 ? new Date().toISOString() : undefined,
                }))
              }
            >
              <Ant.Radio value={1}>同意</Ant.Radio>
              <Ant.Radio value={2}>不同意</Ant.Radio>
            </Ant.Radio.Group>
          </Ant.Row>
          <Ant.Row style={{ "margin-bottom": "8px" }}>
            <Ant.Typography.Text>
              我們向您寄送第三人(關係企業或企業客戶)的商品/服務資訊。未勾選者視為不同意，若不同意並不影響使用我們的服務。
              <span style={{ color: "#0b90fc" }}>
                建議您勾選「同意」，才不會錯過好康的機會、折扣資訊、優惠方案及更多的服務。
              </span>
            </Ant.Typography.Text>
          </Ant.Row>
          <Ant.Row className="radio-group-2">
            <Ant.Radio.Group
              value={agree.second}
              onChange={e =>
                setAgree(state => ({ ...state, second: e.target.value }))
              }
            >
              <Ant.Radio value={1}>同意</Ant.Radio>
              <Ant.Radio value={2}>不同意</Ant.Radio>
            </Ant.Radio.Group>
          </Ant.Row>
          <DefaultButton
            onClick={() => {
              if (agree.first !== 1) return;
              if (agree.second === undefined) return;
              onSubmit();
            }}
          >
            <div
              className={`default-button ${
                agree.first !== 1 || agree.second === undefined
                  ? "disabled"
                  : ""
              }`}
            >
              {welcomePageDisplay.buttonSentence
                ? welcomePageDisplay.buttonSentence
                : "進入問卷"}
            </div>
          </DefaultButton>
        </PrivacyWrapper>
      </Main>
      <Footer>
        <img src={chtLogo} alt="中華電信" />
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: #f4faff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Header = styled.div`
  display: none;
`;

const Main = styled.div`
  display: block;
  width: 100%;
  max-width: 800px;
  height: auto;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-top: 37px;

  @media screen and (max-width: 823px) {
    min-height: 532px;
    margin-top: 0;
    border-radius: 0;
  }

  .banner {
    width: 800px;
    /* min-height: 227px; */
    overflow: hidden;
    display: block;
    /* border: solid 1px #cccccc; */
    /* background-color: #cccccc; */
    @media screen and (max-width: 823px) {
      width: 100%;
      height: 0px;
      padding-bottom: calc(100% * (227 / 800));
    }
    @media screen and (max-width: 639px) {
      padding-bottom: calc(100% * (151 / 375));
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  .title {
    text-align: center;
    font-size: ${FontSize.largeTitle}px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5rem;
    letter-spacing: 0.48px;
    text-align: center;
    color: ${Color.ChtBlue_8};
    padding: 42px 36px 0px;
    @media screen and (max-width: 823px) {
      padding: 20px 20px 0px;
      letter-spacing: 0.32px;
      line-height: 1.33rem;
    }
  }
  .closeBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 36px 36px 60px;
    @media screen and (max-width: 823px) {
      padding-top: 96px;
      padding-bottom: 204px;
    }
    .btn {
      min-width: 240px;
      height: 48px;
      box-shadow: 1px 1px 0.5px 0 rgba(0, 20, 37, 0.1);
      font-size: ${FontSize.head}px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5rem;
      letter-spacing: 2px;
      text-align: center;
      position: relative;
      @media screen and (max-width: 823px) {
        min-width: 98px;
        height: 40px;
      }
    }
    .btn-blue {
      border-color: ${Color.ChtBlue_5};
      background-color: ${Color.ChtBlue_5};
      color: #ffffff;
      &:hover {
        border-color: ${Color.ChtBlue_4};
        background-color: ${Color.ChtBlue_4};
      }
      &:active {
        border-color: ${Color.ChtBlue_8};
        background-color: ${Color.ChtBlue_8};
      }
      &:focus {
        /* -webkit-box-shadow: 0 0 4px 2px ${Color.ChtBlue_5};
        -moz-box-shadow: 0 0 4px 2px ${Color.ChtBlue_5};
        box-shadow: 0 0 4px 2px ${Color.ChtBlue_5};
        border: solid 1px ${Color.ChtBlue_5}; */
        border-color: #0691ff;
        background-color: #0691ff;
        &::after {
          position: absolute;
          width: calc( 100% - 2px );
          height: calc( 100% - 2px );
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          content: '';
          display: block;
          border: solid 1px #f4f6fc;
          background-color: transparent;
        }
      }
      &[disabled] {
        background-color: ${Color.Black_15};
        border-color: transparent;
        color: ${Color.Black_45};
      }
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  max-width: 800px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 110px;
    height: auto;
  }
  @media screen and (max-width: 832px) {
    height: 75px;
    img {
      width: 80px;
    }
  }
`;

const MainTitleWrapper = styled.div`
  padding: 26px 36px 22px;

  .main-title {
    font-size: 28px;
    color: #0075d3;
    font-weight: 500;
  }
`;

const PrivacyWrapper = styled.div`
  padding: 24px 36px 70px;

  @media screen and (max-width: 823px) {
    padding: 24px 30px 40px;
  }

  .subtitle {
    font-size: 20px;
    font-weight: 500;
    color: #324250;
    margin-bottom: 60px;
  }

  .privacy-display-wrapper {
    position: relative;
    margin-bottom: 16px;

    @media screen and (max-width: 823px) {
      margin-bottom: 24px;
    }
  }

  .ant-radio-wrapper {
    z-index: 0;
  }

  .radio-group-2 {
    margin-bottom: 60px;

    @media screen and (max-width: 823px) {
      margin-bottom: 40px;
    }
  }
`;

const HeaderTitle = styled.div`
  padding: 16px 0px 12px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #324250;

  @media screen and (max-width: 823px) {
    padding: 16px 0;
  }
`;

const DefaultButton = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .default-button {
    min-width: 240px;
    height: 48px;
    background-color: #209cff;
    font-size: ${FontSize.head}px;
    color: #ffffff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 823px) {
      min-width: 98px;
      height: 40px;
      width: 100%;
    }

    &:hover {
      background-color: #53b3ff;
      border: solid 1px #53b3ff;
      color: #ffffff;
    }

    &:active {
      background-color: #0083ec;
      border: solid 1px #0083ec;
      color: #ffffff;
    }

    &:focus {
      -webkit-box-shadow: 0 0 4px 2px #209cff;
      -moz-box-shadow: 0 0 4px 2px #209cff;
      box-shadow: 0 0 4px 2px #209cff;
      border: solid 1px #209cff;
      color: #ffffff;
    }

    &.disabled {
      background-color: rgba(0, 0, 0, 0.15);
      color: rgba(0, 0, 0, 0.25);

      &:hover {
        border: none;
      }
    }
  }
`;

const ColorBar = styled.div`
  height: 4px;
  background-image: linear-gradient(
    to right,
    #209cff 0%,
    #0267b9 20%,
    #fcaf15 39%,
    #ff874d 60%,
    #96d34f 81%,
    #47d382 100%
  );
`;

export default WelcomePage;
